






















































































import {Component} from 'vue-property-decorator';
import {
  dispatchDeleteRecommendation,
  dispatchGetBiomarkersForList,
  dispatchGetRecommendationsForTable,
} from '@/store/crud/actions';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {readOneBiomarker} from '@/store/crud/getters';
import {IRecommendation} from '@/interfaces/recommendations';

@Component({components: {ConfirmationModal}})
export default class Recommendations extends AppComponent {
  public headers = [
    {
      text: 'Biomarker name',
      sortable: true,
      value: 'biomarker.name',
      align: 'left',
    },
    {
      text: 'Biomarker code',
      sortable: true,
      value: 'biomarker.questBiomarkerCode',
      align: 'left',
    },
    {
      text: 'Sex',
      sortable: true,
      value: 'sex',
      align: 'left',
    },
    {
      text: 'In Range',
      sortable: true,
      value: 'inRange',
      align: 'left',
    },
    {
      text: 'Range Type',
      sortable: true,
      value: 'outOfRangeType',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingRecommendationsData = false;

  /*
   * Component starting point
   */
  public created() {
    this.fetchData();
  }

  /**
   * fetches data asyncrhonously from store / server
   * and displays loading indicator
   */
  public async fetchData() {
    this.fetchingRecommendationsData = true;
    await this.$nextTick();
    await dispatchGetRecommendationsForTable(this.$store);
    await dispatchGetBiomarkersForList(this.$store);
    this.fetchingRecommendationsData = false;
  }

  /**
   * Gets the biomarker name for the given biomarker id
   */
  public getBiomarkerName(biomarkerId: string) {
    const biomarker = this.getBiomarkerData(biomarkerId);

    if (biomarker && Object.keys(biomarker).length > 0) {
      return biomarker.name || '';
    }

    return '';
  }

  /**
   * gets the Biomarker questBiomarkerCode for the given biomarker id
   */
  public getBiomarkerQuestBiomarkerCode(biomarkerId: string) {
    const biomarker = this.getBiomarkerData(biomarkerId);

    if (biomarker && Object.keys(biomarker).length > 0) {
      return biomarker.questBiomarkerCode || '';
    }

    return '';
  }

  public customFilter(items, search: string, element: IRecommendation) {
    search = search.toLowerCase().trim();
    if (
      element.outOfRangeType &&
      element.outOfRangeType.length > 0 &&
      element.outOfRangeType
        .toLowerCase()
        .trim()
        .includes(search)
    ) {
      return element;
    } else if (
      !element.outOfRangeType &&
      'In Range'
        .toLowerCase()
        .trim()
        .includes(search)
    ) {
      return element;
    }
    if (element.biomarkerId) {
      const name = this.getBiomarkerName(element.biomarkerId);
      const questBiomarkerCode = this.getBiomarkerQuestBiomarkerCode(element.biomarkerId);
      if (
        name.toLowerCase().includes(search) ||
        questBiomarkerCode.toLowerCase().includes(search)
      ) {
        return element;
      }
    }
    return false;
  }

  /**
   * Gets a single biomarker from the store
   * a fetch should be done before this is called
   */
  public getBiomarkerData(biomarkerId: string) {
    return readOneBiomarker(this.$store)(biomarkerId);
  }

  /**
   * Delete a food and refresh the screen
   */
  public async deleteRecommendation(recommendationId: string) {
    await dispatchDeleteRecommendation(this.$store, {recommendationId});
    await this.fetchData();
  }
}
